import { useDepositMenu } from '~/composables/account/deposit/useMenuDeposit'

export default defineNuxtRouteMiddleware((to, _from) => {
  const { $device, ssrContext } = useNuxtApp()
  if ($device.isMobileOrTablet || !!ssrContext) {
    return
  }
  const currentPath = to.fullPath
  const { menuDepositDesktop, menuDepositBank, updateDataMenuDepositDesktop, updateDataMenuDepositBank } = useDepositMenu()
  menuDepositDesktop.value = updateDataMenuDepositDesktop()
  menuDepositBank.value = updateDataMenuDepositBank()
  const pageNotMaintain = menuDepositDesktop.value.filter((item) => item.isMaintain === false)
  const bankNotMaintain = pageNotMaintain.find((item) => item.key === 'nicepayBanks')
  const currentPage = menuDepositDesktop.value.find((item) => item.fullPath.includes(currentPath))
  if (currentPage && !currentPage?.isMaintain) {
    if (currentPage.key === 'nicepayBanks') {
      const pageBankNotMaintain = menuDepositBank.value.filter((item) => item.isMaintain === false)
      const currentPageBank = menuDepositBank.value.find(
        (item) => item.fullPath === currentPath || currentPath === item.fullPath.split('?')[0]
      )

      if (currentPageBank && !currentPageBank.isMaintain) {
        // TODO
      } else {
        abortNavigation()
        return navigateTo(pageBankNotMaintain[0]?.fullPath)
      }
    }
  } else {
    const link = Array.isArray(bankNotMaintain?.fullPath) ? bankNotMaintain?.link : pageNotMaintain[0]?.fullPath[0]
    abortNavigation()
    return navigateTo(link)
  }
})
